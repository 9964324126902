import {DropdownChainApi} from '@/api/DropdownChainApi';
import {SubmissionApi} from '@/api/SubmissionApi';
import {SubmissionResponseApi} from '@/api/SubmissionResponseApi';
import {SurveyApi} from '@/api/SurveyApi';
import {SurveyFieldApi} from '@/api/SurveyFieldApi';
import {SurveyFieldItemApi} from '@/api/SurveyFieldItemApi';
import {SurveyPageApi} from '@/api/SurveyPageApi';
import {IndexApi} from '@/api/IndexApi';
import {AttachmentApi} from '@/api/AttachmentApi';
import {PlaceAutocompleteApi} from '@/api/PlaceAutocompleteApi';
import {PlaceApi} from '@/api/PlaceApi';

export const attachmentApi = new AttachmentApi();
export const dropdownChainApi = new DropdownChainApi();
export const indexApi = new IndexApi();
export const placeApi = new PlaceApi();
export const placeAutocompleteApi = new PlaceAutocompleteApi();
export const submissionApi = new SubmissionApi();
export const submissionResponseApi = new SubmissionResponseApi();
export const surveyApi = new SurveyApi();
export const surveyFieldApi = new SurveyFieldApi();
export const surveyFieldItemApi = new SurveyFieldItemApi();
export const surveyPageApi = new SurveyPageApi();
