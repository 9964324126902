export enum QuestionType {
  Address = 'address',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkbox-group',
  Date = 'date',
  DateTime = 'datetime',
  DisplayControl = 'display-control',
  Dropdown = 'dropdown',
  DropdownChain = 'dropdown-chain',
  Email = 'email',
  File = 'file',
  Heading = 'heading', // Deprecated?, pages will replace this
  Hidden = 'hidden',
  Long = 'long',
  None = 'none', // for default
  Number = 'number',
  RatingRadio = 'rating-radio',
  RadioGroup = 'radio-group',
  Short = 'short',
  QuestionCollectionPlaceholder = 'question-collection-placeholder',
}
