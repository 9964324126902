import {
  AttachmentCreate,
  AttachmentResource,
  JsonApiResponse,
} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class AttachmentApi extends CrudApiAbstract<
  AttachmentResource,
  AttachmentCreate
> {
  public constructor() {
    super(`${ResourceType.Attachment}s`);
  }

  public async postFile(file: File): Promise<AttachmentResource[]> {
    const data = new FormData();
    data.append('file', file, file.name);
    const response = await this.instance.post<
      JsonApiResponse<AttachmentResource[]>
    >(``, data, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    return response.data;
  }
}
