import {SubmissionCreate, SubmissionResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class SubmissionApi extends CrudApiAbstract<
  SubmissionResource,
  SubmissionCreate
> {
  public constructor() {
    super(`${ResourceType.Submission}s`);
  }
}
