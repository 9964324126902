import {PlaceAutocompleteCreate, PlaceAutocompleteResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceType} from '@/enums';

export class PlaceAutocompleteApi extends CrudApiAbstract<
  PlaceAutocompleteResource,
  PlaceAutocompleteCreate
> {
  public constructor() {
    super(`${ResourceType.PlaceAutocomplete}`);
  }
}
