export enum ResourceType {
  Auth = 'auth',
  Attachment = 'attachment',
  DisplayControl = 'display-control',
  DropdownChain = 'dropdown-chain',
  Question = 'question',
  QuestionCollection = 'question-collection',
  QuestionItem = 'question-item',
  Place = 'place',
  PlaceAutocomplete = 'place-autocomplete',
  Submission = 'submission',
  SubmissionResponse = 'submission-response',
  Survey = 'survey',
  SurveyField = 'survey-field',
  SurveyFieldItem = 'survey-field-item',
  SurveyPage = 'survey-page',
  User = 'user',
  UserGroup = 'user-group',
  Version = 'version',
}
