import axios, {AxiosInstance, AxiosResponse} from 'axios';

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  /**
   * HttpClient Constructor
   *
   * @param resource Should be ResourceType but in most cases plural
   */
  public constructor(resource: string) {
    this.instance = axios.create({
      baseURL: `${process.env.VUE_APP_CUSTOMER_API_BASE_URL}/${resource}`,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      withCredentials: false,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
    this.instance.interceptors.response.use(
      function(successRes) {
        return successRes;
      },
      async error => {
        if (error.response.status === 401) {
          // force a refresh to auth
          location.href = `/auth?redirect=${encodeURIComponent(
            location.pathname
          )}`;
        }
        return Promise.reject(error);
      }
    );
  };

  private handleResponse = ({data}: AxiosResponse) => data;

  protected handleError = (error: Error) => Promise.reject(error);
}
