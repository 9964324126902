import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {Route} from '@/enums';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Route.Home,
  },
  {
    path: '/:id/preview',
    name: Route.SurveyPreview,
    meta: {title: 'Preview Survey'},
    component: () =>
      import(/* webpackChunkName: "survey" */ '../views/Survey.vue'),
  },
  {
    path: '/:id/:key?',
    name: Route.Survey,
    meta: {title: 'Survey'},
    component: () =>
      import(/* webpackChunkName: "survey" */ '../views/Survey.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === Route.Home) {
    location.href = 'https://www.communitech.ca';
  }
  document.title =
    `${to.meta.title} - Form Builder` || 'Communitech Form Builder';
  next();
});

export default router;
