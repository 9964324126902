





















import Vue from 'vue';
import Component from 'vue-class-component';
import {indexApi} from '@/api';

@Component
export default class App extends Vue {
  private version = process.env.VERSION;
  private apiVersion = '';

  async created() {
    this.apiVersion = await indexApi.getVersion();
    console.log('API version:', this.apiVersion);
    console.log('App version:', this.version);
  }
}
